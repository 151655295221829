<template>
  <header class="relative h-3/4 mb-24 grid items-center">
    <div
      class="absolute h-full w-full bg-[url('@/assets/img/header.jpg')] bg-right bg-no-repeat bg-cover md:bg-fixed grid items-center slanted-box-5-percent"
    ></div>
    <ad-component class="cursor-grab"></ad-component>
  </header>
</template>

<script>
import AdComponent from "./AdComponent.vue";

export default {
  components: { AdComponent },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
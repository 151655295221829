import { createRouter, createWebHistory } from 'vue-router'
import MainComponent from '../components/MainComponent'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainComponent
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

export default router

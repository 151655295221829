<template>
  <carousel
    :autoplay="autoplayTime"
    :wrapAround="true"
    :transition="transitionTime"
    :pauseAutoplayOnHover="false"
    :itemsToShow="1"
  >
    <slide v-for="rating in ratingList" :key="rating">
      <blockquote class="grid grid-rows-[repeat(3,_auto)] gap-2 m-4">
        <font-awesome-icon icon="fa-solid fa-quote-left" />
        <p class="lg:text-xl italic">{{ rating.review_text }}</p>
        <font-awesome-icon
          icon="fa-solid fa-quote-right"
          class="justify-self-end"
        />
      </blockquote>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
  },
  props: ["ratingList"],
  data() {
    return {
      autoplayTime: 10000,
      transitionTime: 1000,
    };
  },
};
</script>
<template>
  <nav
    class="z-40 absolute top-0 left-0 w-full p-4 text-white rounded-lg grid grid-cols-[auto,_1fr,_auto] items-center gap-4"
  >
    <img
      src="@/assets/img/favagas_komarom_logo.png"
      alt="Favágás Komárom Logo"
      class="h-12 md:h-16 drop-shadow-lg bg-white rounded-full"
    />
    <p class="text-xl font-bold drop-shadow-lg hidden md:block">Szalai Favágás Komárom</p>
    <font-awesome-icon icon="fa-solid fa-bars" size="xl" class="hidden"/>
  </nav>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {},
  methods: {},
  computed: {},
};
</script>
<template>
  <nav-bar-component></nav-bar-component>
  <router-view />
  <footer-component></footer-component>
</template>

<script>
import NavBarComponent from "@/components/NavBarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  components: { NavBarComponent, FooterComponent },
  methods: {},
  computed: {},
};
</script>
<template>
  <carousel
    :autoplay="autoplayTime"
    :wrapAround="true"
    :transition="transitionTime"
    :pauseAutoplayOnHover="true"
  >
    <slide :key="1">
      <div
        class="bg-white/20 backdrop-blur-md border border-slate-400 p-4 lg:p-8 rounded-lg shadow-lg m-4 md:max-w-[75%]"
      >
        <h2
          class="text-2xl lg:text-4xl text-white font-bold uppercase mb-4 md:mb-8 underline decoration-4 underline-offset-4 decoration-custom-orange"
        >
          Telek takarítás
        </h2>
        <p class="lg:text-xl text-white">
          Vállaljuk cégek, magánszemélyek zöldterületeinek fűnyírását,
          kaszálását, rendbetételét.
        </p>
      </div>
    </slide>

    <slide :key="2">
      <div
        class="bg-white/20 backdrop-blur-md border border-slate-400 p-4 lg:p-8 rounded-lg shadow-lg m-4 md:max-w-[75%]"
      >
        <h2
          class="text-2xl lg:text-4xl text-white font-bold uppercase mb-4 md:mb-8 underline decoration-4 underline-offset-4 decoration-custom-orange"
        >
          Tetőmosás
        </h2>
        <p class="lg:text-xl text-white mb-4 md:mb-8">
          További információkért látogasson el a "tetomosaskomarom" Facebook
          oldalunkra!
        </p>
        <a href="https://www.facebook.com/tetomosaskomarom" target="_blank">
          <div
            class="w-fit mx-auto lg:text-xl text-white font-bold bg-custom-orange rounded-full px-8 py-2 shadow-lg md:hover:shadow-none md:hover:translate-y-1 transition-all"
          >
            Facebook oldal
          </div>
        </a>
      </div>
    </slide>

    <slide :key="3">
      <div
        class="bg-white/20 backdrop-blur-md border border-slate-400 p-4 lg:p-8 rounded-lg shadow-lg m-4 md:max-w-[75%]"
      >
        <h2
          class="text-2xl lg:text-4xl text-white font-bold uppercase mb-4 md:mb-8 underline decoration-4 underline-offset-4 decoration-custom-orange"
        >
          Emelőkosaras autó bérelhető!
        </h2>
        <p class="lg:text-xl text-white">
          További információkért hívja a
          <a href="tel:+36302020624" class="whitespace-nowrap font-bold">
            (+36) 30 20-20-624-es
          </a>
          telefonszámot!
        </p>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      autoplayTime: 0,
      transitionTime: 0,
    };
  },
  mounted() {
    (this.autoplayTime = 10000), (this.transitionTime = 1000);
  },
};
</script>
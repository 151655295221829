<template>
  <header-component></header-component>
  <content-component></content-component>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import ContentComponent from "@/components/ContentComponent.vue";

export default {
  components: { HeaderComponent, ContentComponent },
  name: "MainComponent",
  methods: {},
  computed: {},
};
</script>
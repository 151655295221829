import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import './index.css';

//import '../node_modules/aos/dist/aos.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faBars, faChevronDown, faChevronLeft, faChevronRight, faPhone, faEnvelope, faQuoteLeft, faQuoteRight, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'


library.add(faBars);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faPhone);
library.add(faEnvelope);
library.add(faQuoteLeft);
library.add(faQuoteRight);
library.add(faCopyright);

library.add(faFacebook);
library.add(faInstagram);

const app = createApp(App);

app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
  <template>
  <main>
    <section
      class="w-11/12 md:w-3/4 mx-auto mb-24 grid xl:grid-cols-2 gap-4 xl:gap-16"
    >
      <div>
        <div
          class="grid grid-cols-[max-content,_max-content] gap-2 mb-4 md:mb-8"
        >
          <div class="bg-custom-orange w-4 h-full rounded-lg"></div>
          <h2 class="text-2xl lg:text-4xl font-bold">Bemutatkozás</h2>
        </div>
        <p class="mb-4">
          Fiatal, jól felszerelt, folyamatosan bűvülő gépparkunkkal vállaljuk
          fák, veszélyes fák kivágását, telkek kitakarítását, magasgazvágást,
          fűnyírást, fűkaszálást, bozótirtást, parlagfű irtást, valamint a
          favágás után maradt tuskó kimarását és a keletkezett zöldhulladék
          darálását, elszállítását akár az ország bármely pontján, de elsősorban
          Komárom, Tata, Tatabánya, Oroszlány és Győr vonzáskörzetében.
          Cégeknek, Önkormányzatoknak és magánszemélyeknek egyaránt. Több éves
          tapasztalattal és teljeskörű felelősségbiztosítással rendelkezünk!
          Keressen bennünket bizalommal elérhetőségeink egyikén és igyekszünk
          megoldást találni a problémájára!
        </p>
        <div
          class="grid grid-cols-[max-content,_max-content] items-center gap-x-2 mb-4"
        >
          <font-awesome-icon icon="fa-solid fa-phone" />
          <a href="tel:+36302020624" class="font-bold">+36302020624</a>
          <font-awesome-icon icon="fa-solid fa-envelope" />
          <a
            href="mailto:favagaskomarom@gmail.com"
            class="font-bold underline md:hover:text-custom-orange md:transition-colors"
          >
            favagaskomarom@gmail.com
          </a>
        </div>
        <p>
          További fotókért, kérem látogasson el
          <a
            href="https://www.facebook.com/favagaskomarom"
            target="_blank"
            class="font-bold underline md:hover:text-custom-orange md:transition-colors"
          >
            Facebook</a
          >
          és
          <a
            href="https://www.instagram.com/favagaskomarom"
            target="_blank"
            class="font-bold underline md:hover:text-custom-orange md:transition-colors"
            >Instagram</a
          >
          oldalainkra.
        </p>
      </div>
      <div class="self-center">
        <img
          class="rounded-lg shadow-lg w-full"
          src="@/assets/img/favagas_komarom_csapata.jpg"
          alt="A Favágás Komárom csapata"
        />
        <figcaption class="italic ml-2 mt-2">
          A Favágás Komárom csapata
        </figcaption>
      </div>
    </section>

    <section
      class="w-11/12 md:w-3/4 mx-auto flex flex-wrap justify-center gap-x-24 gap-y-12 mb-24"
    >
      <img
        class="w-52 drop-shadow-lg"
        src="@/assets/img/tanusitvany_kiemelten_megbizhato_2024_hu.png"
        alt="BCP Rating Tanúsítvány"
      />
      <img
        class="w-52 drop-shadow-lg"
        src="@/assets/img/tanusitvany_kiemelten_megbizhato_2024_en.png"
        alt="BCP Rating Tanúsítvány"
      />
    </section>

    <!--<hr class="mb-24 border-custom-orange w-1/2 mx-auto shadow-lg" />-->

    <section class="w-11/12 md:w-3/4 mx-auto mb-24">
      <div
        class="grid grid-cols-[max-content,_max-content] gap-2 mb-4 md:mb-8 md:col-span-full"
      >
        <div class="bg-custom-orange w-4 h-full rounded-lg"></div>
        <h2 class="text-2xl lg:text-4xl font-bold">Rólunk mondták</h2>
      </div>
      <rating-component
        :rating-list="ratingList"
        class="cursor-grab"
      ></rating-component>
    </section>

    <div class="bg-gray-100 h-4 slanted-box-top"></div>

    <section class="bg-gray-100">
      <div class="w-11/12 md:w-3/4 mx-auto pt-24 pb-24">
        <div
          class="grid grid-cols-[max-content,_max-content] gap-2 mb-4 md:mb-8 md:col-span-full"
        >
          <div class="bg-custom-orange w-4 h-full rounded-lg"></div>
          <h2 class="text-2xl lg:text-4xl font-bold">Szolgáltatásaink</h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/favagas.jpg"
              alt="Favágás, veszélyes fák kivágása, gallyazás"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Favágás, veszélyes fák kivágása, gallyazás
              </h3>
              <p>
                Nem ismerünk lehetetlent. Nem rettenünk meg a túl magas,
                elszáradt, esetleg vezetékbe nőtt fáktól sem. Alpin technika,
                illetve emelőkosaras autónk segítségével minden fát ki tudunk
                vágni, le tudunk gallyazni és minden problémára találunk
                megoldást.
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/tuskomaras.jpg"
              alt="Tuskómarás"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Tuskómarás
              </h3>
              <p>
                Mi az a tuskómarás? A fa gyökereinek, tuskójának eltávolítása
                marógép segítségével. Amennyiben a fa kivágása után parkosítana,
                térkövezne vagy csak egész egyszerűen nincs helye az ott maradt
                tuskónak, mi kimarjuk Önnek, anélkül, hogy kárt tennénk az
                udvarában. Mindössze 80 cm szélességre van szükségünk, hogy a
                géppel a tuskó mellé tudjuk állni.
              </p>
            </div>
          </div>

          <!--
          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/darabolas.jpg"
              alt="Darabolás"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Darabolás
              </h3>
              <p>
                Megérkezett a tűzifa, de csak letették és fel kéne vágni
                darabokra? Keressenek bennünket, mi segítünk a feldolgozásában.
              </p>
            </div>
          </div>
          -->

          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/agdaralas.jpg"
              alt="Ágdarálás, zöldhulladék kezelés"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Ágdarálás, zöldhulladék kezelés
              </h3>
              <p>
                A fakivágás után 15-20 köbméter zöldhulladék is keletkezhet,
                amelynek az eltüntetésére a legegyszerűbb mód az ágdarálás. A
                darálást követően kb. 5 köbméter gallyból keletkezik 1 köbméter
                apríték, amely kiváló avartakaró lehet bokrok és fák alá, de
                akár begyújtóst is készíthetünk belőle a fűtési szerzonban.
                Azonban, ha nem szükségszerű a darálás, akkor a billenő platós
                3,5 tonnás teherautónknak köszönhetően darálás nélkül is
                könnyedén el tudjuk szállítani a zöldhulladékot.
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/telek_takaritas.jpg"
              alt="Telek takarítás"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Telek takarítás, magasgazvágás, fűnyírás, fűkaszálás,
                bozótirtás, parlagfű irtás
              </h3>
              <p>
                Vállaljuk cégek, magánszemélyek zöldterületeinek fűnyírását,
                kaszálását, rendbetételét. Esetleg vásárolt, vagy örökölt egy
                több éve gondozatlan területet? Hívjon fel bennünket, mutassa
                meg és mi üresen, használhatóan adjuk vissza Önnek.
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/emelokosaras_auto_berles.jpg"
              alt="Emelőkosaras autó bérelhető"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Emelőkosaras autó bérelhető
              </h3>
              <p>
                Egy cserepet igazítana meg, vagy a ragalját festené le? Hívjon
                bennünket és akár egy órára is biztosítjuk a 20 méter
                munkamagasságig emelő emelőkosaras autónkat. Komárom területén
                díjmentes kiszállással.
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <img
              class="rounded-t-lg w-full"
              src="@/assets/img/activity/tetomosas.jpg"
              alt="Tetőmosás"
            />
            <div class="p-8">
              <h3 class="mb-3 md:mb-4 text-xl lg:text-2xl font-bold">
                Tetőmosás
              </h3>
              <p>
                Vállaljuk az évek során szennyeződött, mohásodott tetők
                szakszerű tisztítását, mosását. A tisztítást követően nem csak
                esztétikailag lesz szép az épület teteje, hanem a környezeti
                hatásokkal szemben is ellenállóbb lesz, mert eltávolítjuk a
                mohát, melynek hosszútávú jelenléte beázáshoz vezethet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="bg-gray-100 h-4 slanted-box-bottom mb-24"></div>

    <section class="w-11/12 md:w-3/4 mx-auto mb-24">
      <div
        class="grid grid-cols-[max-content,_max-content] gap-2 mb-4 md:mb-8 md:col-span-full"
      >
        <div class="bg-custom-orange w-4 h-full rounded-lg"></div>
        <h2 class="text-2xl lg:text-4xl font-bold">Áraink</h2>
      </div>
      <p>
        Árainkról kérem érdeklődjön elérhetőségeink egyikén. Az árajánlat
        díjtalan.
      </p>
    </section>
  </main>
</template>

<script>
// import AOS from "aos";
import axios from "axios";
import RatingComponent from "./RatingComponent.vue";

export default {
  name: "ContentComponent",
  components: { RatingComponent },
  data() {
    return {
      ratingList: [],
    };
  },
  methods: {
    getRatings() {
      axios
        .get(
          "https://favagas-komarom-default-rtdb.europe-west1.firebasedatabase.app/ratings.json"
        )
        .then((response) => {
          const firabaseResponseList = response.data;

          if (firabaseResponseList) {
            for (const [key, value] of Object.entries(firabaseResponseList)) {
              this.ratingList = value.ratings.data;
            }
          }
        });
    },
  },
  computed: {},
  beforeMount() {
    this.getRatings();
  },
  mounted() {
    // AOS.init();
  },
};
</script>